import Cookies from 'js-cookie';
import React from 'react';
import { Link } from 'react-router-dom';
import JWT from 'jsonwebtoken';
import Swal from './helpers/Swal';
import Constants from './constants/constants';

const Util = {
  /**
   * Handler for errors
   * @param {object} error - An Error object
   * @param {string} message - If set, this is the message that will be displayed
   * @returns {void}
   */
  handleError: async (error, message) => {
    let APIMessage = '';
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    ) APIMessage = error.response.data.error;

    if (
      error &&
      error.response &&
      error.response.status === 403 &&
      !Cookies.get(Constants.AUTH__COOKIE_NAME)
    ) {
      await Swal.fireError({
        ...Swal.TIMER(5000),
        title: 'Oops...',
        html: 'Session has ended. Please reconnect, you will be redirected in <b>5</b> seconds.',
      });

      // Redirect to login screen
      window.location = `${Constants.ROUTE__LOGIN}`;
    } else {
      Swal.fireError(
        'Oops...',
        `${message || APIMessage || `Something unexpected happened: ${error.message || error}`}`,
      );
    }
  },

  /**
   * Show informational dialog
   * @param {*} title Title
   * @param {*} message Message
   * @returns {void}
   */
  informationalDialog: (title, message) => {
    Swal.fire({
      title,
      text: message,
    });
  },
  /**
   * handler to check if user has admin or readonly access
   * get jwt from cookie, decode it and check the role property
   *  @returns {string} returns admin for admins and readonly for normal ones
   *
   */
  getUserRole: () => {
    const token = Cookies.get(Constants.AUTH__COOKIE_NAME) || '';
    if (token) {
      const decodedToken = JWT.decode(token);
      if (decodedToken.role) {
        return decodedToken.role;
      }
      // If no role is set
      return Constants.ROLE__READ_ONLY;
    }
    return Constants.ROLE__READ_ONLY;
  },

  getUserID: () => {
    const token = Cookies.get(Constants.AUTH__COOKIE_NAME) || '';
    return token ? JWT.decode(token)._id : false;
  },

  /**
   * Check if a user has a certain permission
   * get jwt from cookie, decode it and check the role property
   *  @param {String} permission - Permission that needs to be checked
   *  @param {String} permissionValue - Value for the permission: read-only or write
   *  @returns {Boolean} returns true if user has the expected permission with expected value,
   *  or just any value for that permission if no permissionValue was given. Return false otherwise.
   *
   */
  userHasPermission: (permission, permissionValue) => {
    const token = Cookies.get(Constants.AUTH__COOKIE_NAME) || '';
    if (token) {
      const decodedToken = JWT.decode(token);
      const { permissions } = decodedToken;

      if (permissions) {
        if (permissions[permission]) {
          if (permissionValue) {
            return (permissions[permission] === permissionValue);
          }
          return true;
        }
        return false;
      }
      throw Error('No permissions set in JWT token');
    }
    return false;
  },

  renderAdoptionDashboardLink: (organisationName, organisationId, openAdoptionDashboard, product) => (
    <Link
      to={Constants.MENU__LINK__ADOPTION_DASHBOARD}
      onClick={openAdoptionDashboard}
      data-link={Constants.MENU__NAME__ADOPTION_DASHBOARD}
      data-id={organisationId}
      data-product={product}
    >
      {organisationName}
    </Link>
  ),

  /**
   * Function for testing whether or not an email address has a correct format
   * @param {string} email - Email format for testing
   * @returns {boolean} boolean value that indicates whether or not an email address is valid.
   */
  validateEmail: (email) => {
    // Regex to test if the given email meets the salesforce requirements
    // eslint-disable-next-line max-len
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\,;:\s@"]+)*))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regex.test(email);
  },

  /**
   * Prints a simplified view per edition
   *  @param {String} edition - edition name
   *  @returns {String} - simplified view per edition
   */
  renderEdition: edition => (<span className="slds-badge">{edition?.substring(0, 2)?.toUpperCase()}</span>),

  /**
   * Prints a simplified view per tier
   *  @param {String} tierOrder - priority of the tier
   *  @returns {String} - simplified view per tier
   */
  renderTier: tierOrder => (<span className={`slds-badge tier-${tierOrder}`}>{tierOrder}</span>),

  /**
   * Abbreviate text to a maxLength, if longer: add ...
   * @param {string} str - The string to abbreviate
   * @param {number} maxLength - The max length allowed for the string to be
   * @returns {string} The abbreviated string
   */
  abbreviate: (str, maxLength) => {
    if (typeof str === 'string') {
      return str.length > maxLength ? `${str.substring(0, maxLength)}...` : str.substring(0, maxLength);
    }
    return str;
  },

  /**
   * Search for a name that matches the search results
   * @param {array} array - an array of objects in which we are looking for a matching name
   * @param {string} searchText - the text entered in the input field
   * @returns {array} filtered array with names that match the search results
   */
  searchByName: (array, searchText) => (array.filter(a => a.name.toString().toLowerCase().includes(
    searchText.toString().toLowerCase(),
  ))),

  /**
   * Finds first possible order number based on numbers in array
   * @param {array} array - an array of numbers in which we are looking for the next available ordinal number
   * @param {number} size - the maximum number to search for
   * @returns {number} next ordinal number based on numbers in array
   */
  findFirstMissingOrderNumber: (array, size) => {
    let i;

    /*
     * Mark arr[i] as visited by making
     * arr[arr[i] - 1] negative. Note that
     * 1 is subtracted because index start
     * from 0 and positive numbers start from 1
     */
    for (i = 0; i < size; i += 1) {
      const x = Math.abs(array[i]);
      // eslint-disable-next-line no-param-reassign
      if (x - 1 < size && array[x - 1] > 0) { array[x - 1] = -array[x - 1]; }
    }

    // Return the first index value at which is positive
    for (i = 0; i < size; i += 1) { if (array[i] > 0) return i + 1; } // 1 is added because indexes start from 0

    return size + 1;
  },

  /**
   * Search object with data by ID and returns property name
   * @param {array} array - an array of objects with properties _id and name
   * @param {string} id - id of the object we are looking for
   * @param {boolean} userNameProperty - defines whether we are looking for property userName, not name
   * @returns {string} name for the object or empty string if not found
   */
  returnPropertyNameById: (array, id, userNameProperty) => {
    if (array?.length) {
      // get object based on ID
      const findObjectById = array.find(arr => arr._id === id);

      if (findObjectById) {
        // depending on the props passed, return the name or username
        return userNameProperty ? findObjectById.username : findObjectById.name;
      }

      // if object is not found, then return empty string
      return '';
    }

    return '';
  },

  /**
   * Function that generates random string
   * @returns {string} random string
   */
  generateRandomString: () => require('crypto').randomBytes(32).toString('hex').slice(0, 20),

  /**
   * Handler called after selecting an item in Transfer List component
   * @param {array} dataForSelect - array with data to select
   * @param {array} selectedData - array with selected data
   * @param {array} allData - array with all available data
   * @param {string} id - id of the selected item
   * @param {bool} checked - whether the item is selected
   * @param {func} setDataForSelect - function that sets dataForSelect
   * @param {func} setSelectedData - function that sets selectedData
   * @param {number} maxNumberOfItems - Maximum number of items (questions) that can be selected
   * @returns {object|boolean} - Swal object if validation could not pass, true otherwise
   */
  handleSelectItemInTransferList: (
    dataForSelect, selectedData, allData, id, checked, setDataForSelect, setSelectedData,
  ) => {
    const dataForSelectCopy = [...dataForSelect];
    const selectedDataCopy = [...selectedData];

    if (checked && id !== 'all' && id !== 'remove-all') {
      // filter out dataForSelect without the the chosen items
      setDataForSelect(dataForSelectCopy.filter(data => data._id !== id));

      // find the selected item to move it to the column with selected data
      const findSelectedData = allData.find(data => data._id === id);

      // add this item to selectedData array
      const newArray = [...selectedDataCopy, findSelectedData];

      // sort an array by id and set new state
      newArray.sort((a, b) => a._id.localeCompare(b._id));

      setSelectedData(newArray);
    } else if (checked && id === 'all') {
      // Transfer all the items to the right column (selectedData)
      setSelectedData(allData);
      setDataForSelect([]);
    } else if (checked && id === 'remove-all') {
      // Transfer all the items to the left column (dataForSelect)
      setSelectedData([]);
      setDataForSelect(allData);
    } else if (!checked && id !== 'all' && id !== 'remove-all') {
      /*
       * filter the chosen item from the selectedData column and
       * move them to the left column dataForSelect
       */
      setSelectedData(selectedData.filter(data => data._id !== id));

      // find the selected item to move it to the dataForSelect column
      const findSelectedData = allData.find(data => data._id === id);

      // add this item to dataForSelect array
      const newArray = [...dataForSelectCopy, findSelectedData];

      // sort an array by id and set new state
      newArray.sort((a, b) => a._id.localeCompare(b._id));
      setDataForSelect(newArray);
    }

    return true;
  },

  /**
   * Renders a number, according with the Health Score classification
   * @param {number} value - Number to print
   * @param {number} max - max value to calculate ranges
   * @returns {object} HTML for the button
   */
  renderHealthScoreColor: (value, max) => {
    const greenLimit = Math.round((max * Constants.HS__GREEN_LIMIT));
    const yellowLimit = Math.round((max * Constants.HS__YELLOW_LIMIT));
    let numberClass = 'slds-badge';

    if (value >= -10) {
      if (value >= greenLimit) {
        numberClass += ' slds-theme_success';
      } else if (value < greenLimit && value >= yellowLimit) {
        numberClass += ' slds-theme_warning';
      } else {
        numberClass += ' slds-theme_error';
      }

      return (
        <span
          className={numberClass}
          style={{ marginLeft: '5px', marginRight: '5px' }}
        >
          {value.toString()}
        </span>
      );
    }

    return null;
  },

  /**
   * Renders the health score, the HS trend, and the churn prob in one single block
   * @param {number} healthScore - HS value
   * @param {number} healthScoreTrend - diff between sprint HS and current week
   * @param {string} churnProb - churn probability flag
   * @param {number} maxHealthScore - max health score, to calculate ranges
   * @returns {object} HTML for the button
   */
  renderHealthScoreBlock: (healthScore, healthScoreTrend, churnProb, maxHealthScore) => {
    const greenLimit = Math.round((maxHealthScore * Constants.HS__GREEN_LIMIT));
    const yellowLimit = Math.round((maxHealthScore * Constants.HS__YELLOW_LIMIT));

    // Generate HS badge
    let healthScoreBadgeClass = 'slds-badge';

    if (healthScore >= -10) {
      if (healthScore >= greenLimit) {
        healthScoreBadgeClass += ' slds-theme_success';
      } else if (healthScore < greenLimit && healthScore >= yellowLimit) {
        healthScoreBadgeClass += ' slds-theme_warning';
      } else {
        healthScoreBadgeClass += ' slds-theme_error';
      }

      // Generate HS Trend Icon
      let healthScoreTrendClass = 'slds-icon slds-icon_x-small';
      let healthScoreTrendIcon = 'assignment'; // Equal Icon

      // We give a buffer of +-2.5 to consider it a trend
      if (healthScoreTrend > 2.5) {
        healthScoreTrendClass += ' slds-icon-text-success';
        healthScoreTrendIcon = 'jump_to_top'; // Arrows up icon
      } else if (healthScoreTrend < -2.5) {
        healthScoreTrendClass += ' slds-icon-text-error';
        healthScoreTrendIcon = 'jump_to_bottom'; // Arrows down icon
      } else {
        healthScoreTrendClass += ' slds-icon-text-light';
      }

      // Generate Churn Warning Icon
      let churnProbClass = 'slds-icon slds-icon_x-small';

      if (churnProb === Constants.HS__CHURN_PROB__HIGH) {
        churnProbClass += ' slds-high-churn-prob';
      } else if (churnProb === Constants.HS__CHURN_PROB__MEDIUM) {
        churnProbClass += ' slds-medium-churn-prob';
      } else {
        churnProbClass += ' slds-low-churn-prob';
      }

      return (
        <>
          <span className="slds-icon_container" title={`${healthScoreTrend} since last week`}>
            <svg
              className={healthScoreTrendClass}
            >
              <use xlinkHref={`/assets/icons/utility-sprite/svg/symbols.svg#${healthScoreTrendIcon}`} />
            </svg>
          </span>
          <span
            className={healthScoreBadgeClass}
            style={{ marginLeft: '5px', marginRight: '5px' }}
          >
            {healthScore.toString()}
          </span>
          { churnProb ?
            (
              <span className="slds-icon_container" title={`${churnProb} churn probability`}>
                <svg className={churnProbClass}>
                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#warning" />
                </svg>
              </span>
            ) :
            (<></>)}
        </>
      );
    }

    return null;
  },

  /**
   * Returns Health Score rank
   * @param {number} healthScore - current health score
   * @returns {String} Either: Healthy, Neutral, At Risk
   */
  getHealthScoreRank: (healthScore) => {
    const greenLimit = Math.round((Constants.HS__MAX_SCORE * Constants.HS__GREEN_LIMIT));
    const yellowLimit = Math.round((Constants.HS__MAX_SCORE * Constants.HS__YELLOW_LIMIT));

    if (healthScore >= greenLimit) {
      return Constants.HS__HEALTHY_VALUE;
    }
    if (healthScore < greenLimit && healthScore >= yellowLimit) {
      return Constants.HS__NEUTRAL_VALUE;
    }

    return Constants.HS__AT_RISK_VALUE;
  },

  /**
   * Renders a number, according with the NPS response
   * @param {number} value - Number to print
   * @returns {object} HTML for the button
   */
  renderNPSColor: (value) => {
    let numberClass = 'slds-badge';

    if (value >= 9) {
      numberClass += ' slds-theme_success';
    } else if (value < 9 && value >= 7) {
      numberClass += ' slds-theme_warning';
    } else {
      numberClass += ' slds-theme_error';
    }

    return (<span className={numberClass}>{value.toString()}</span>);
  },

  /**
   * Return the hexadecimal color based on the HS range
   * @param {number} value - Number to print
   * @param {number} max - max value to calculate ranges
   * @returns {object} HTML for the button
   */
  getHealthScoreColor: (value, max) => {
    const greenLimit = Math.round((max * Constants.HS__GREEN_LIMIT));
    const yellowLimit = Math.round((max * Constants.HS__YELLOW_LIMIT));

    if (value >= greenLimit) {
      return Constants.HS__HEALTHY_COLOR;
    }
    if (value < greenLimit && value >= yellowLimit) {
      return Constants.HS__NEUTRAL_COLOR;
    }

    return Constants.HS__AT_RISK_COLOR;
  },

  /**
   * Beautify Ticket Status: (O) open, (P) pending, (C) closed, (S) solved
   * @param {String} status - Ticket status
   * @returns {object} HTML for the ticket status
   */
  renderSupportTicketStatus: (status) => {
    let classValue = 'slds-badge';
    let value;

    if (status === Constants.ZENDESK_TICKET__STATUS__NEW) {
      value = Constants.ZENDESK_TICKET__STATUS__NEW_BEAUTIFIED;
      classValue += ' slds-theme_warning';
    } else if (status === Constants.ZENDESK_TICKET__STATUS__OPEN) {
      value = Constants.ZENDESK_TICKET__STATUS__OPEN_BEAUTIFIED;
      classValue += ' slds-theme_warning';
    } else if (status === Constants.ZENDESK_TICKET__STATUS__PENDING) {
      value = Constants.ZENDESK_TICKET__STATUS__PENDING_BEAUTIFIED;
      classValue += ' slds-theme_warning';
    } else if (status === Constants.ZENDESK_TICKET__STATUS__SOLVED) {
      value = Constants.ZENDESK_TICKET__STATUS__SOLVED_BEAUTIFIED;
      classValue += ' slds-theme_success';
    } else if (status === Constants.ZENDESK_TICKET__STATUS__CLOSED) {
      value = Constants.ZENDESK_TICKET__STATUS__CLOSED_BEAUTIFIED;
      classValue += ' slds-theme_success';
    }

    return (<span className={classValue}>{value}</span>);
  },

  /**
   * Beautify request status
   * @param {String} status - request status
   * @returns {object} HTML for the request status
   */
  renderFeatureRequestStatus: (status) => {
    let classValue = 'slds-badge';

    if (status === Constants.PENDO_FEEDBACK_STATUS__NOT_REVIEWED ||
      status === Constants.PENDO_FEEDBACK_STATUS__AWAITING_FEEDBACK) {
      classValue += '';
    } else if (status === Constants.PENDO_FEEDBACK_STATUS__PLANNED) {
      classValue += ' slds-theme_inverse';
    } else if (status === Constants.PENDO_FEEDBACK_STATUS__BUILDING) {
      classValue += ' slds-theme_warning';
    } else if (status === Constants.PENDO_FEEDBACK_STATUS__RELEASED) {
      classValue += ' slds-theme_success';
    } else if (status === Constants.PENDO_FEEDBACK_STATUS__DECLINED) {
      classValue += ' slds-theme_error';
    }

    return (<span className={classValue}>{status}</span>);
  },

  /**
   * Render Last Touchpoint date
   * @param {number} daysSinceUpdate - Number to print
   * @param {number} healthScore - based on the HealthScore rank, limits are different
   * @returns {object} HTML for the button
   */
  renderLastTouchpoint: (daysSinceUpdate, healthScore) => {
    let classValue = 'slds-badge';
    let finalValue = '';
    let greenLimit = Constants.TOUCHPOINT__GREEN_LIMIT;
    let yellowLimit = Constants.TOUCHPOINT__YELLOW_LIMIT;

    // If the customer is At Risk, there's a different color configuration
    if (healthScore < (Constants.HS__MAX_SCORE * Constants.HS__YELLOW_LIMIT)) {
      greenLimit = Constants.TOUCHPOINT__AR_GREEN_LIMIT;
      // There's no yellow rank, only green or red
      yellowLimit = Constants.TOUCHPOINT__AR_GREEN_LIMIT;
    }

    if (daysSinceUpdate && daysSinceUpdate <= greenLimit) {
      classValue += ' slds-theme_success';
      finalValue = daysSinceUpdate + ' days';
    } else if (daysSinceUpdate && daysSinceUpdate > greenLimit && daysSinceUpdate <= yellowLimit) {
      classValue += ' slds-theme_warning';
      finalValue = daysSinceUpdate + ' days';
    } else if (daysSinceUpdate && daysSinceUpdate > yellowLimit) {
      classValue += ' slds-theme_error';
      finalValue = daysSinceUpdate + ' days';
    } else {
      classValue += ' slds-theme_error';
      finalValue = 'never';
    }

    return (<span className={classValue}>{finalValue}</span>);
  },

  /**
   * Beautify Ticket Sentiment: (+) Positive, (-) Negative, () Neutral
   * @param {String} sentiment - sentiment to render
   * @returns {object} HTML for the ticket status
   */
  renderSupportTicketSentiment: (sentiment) => {
    let classValue = 'slds-badge';
    let value = '';

    if (sentiment === Constants.ZENDESK_TICKET__SENTIMENT_POSITIVE) {
      value = Constants.ZENDESK_TICKET__SENTIMENT__POSITIVE_BEAUTIFIED;
      classValue += ' slds-theme_success';
    } else if (sentiment === Constants.ZENDESK_TICKET__SENTIMENT_NEGATIVE) {
      value = Constants.ZENDESK_TICKET__SENTIMENT_NEGATIVE_BEAUTIFIED;
      classValue += ' slds-theme_error';
    } else {
      value = ' '; // Print gray empty box when neutral
    }

    return (<span className={classValue}>{value}</span>);
  },

  /**
   * Beautify Date
   * @param {String} date - date to print
   * @returns {object} string with format DD MMM YY
   */
  beautifyDate: (date) => {
    const dateValue = new Date(date.toString());

    return dateValue.toLocaleDateString('en-GB', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    });
  },

  /**
   * Render support ticket requester to highlight unmapped tickets
   * @param {String} requester - can be an org or a visitor
   * @returns {object} HTML for the ticket requester
   */
  renderSupportTicketRequester: (requester) => {
    if (!requester || requester === 'unknown') {
      return (<span className="unknown-requester">UNK</span>);
    }

    return (requester);
  },

  /**
   * Render support ticket type
   * @param {String} type - type assigned in DB
   * @returns {String} beautified string
   */
  renderSupportTicketType: (type) => {
    if (type === Constants.ZENDESK_TICKET__TYPE__QUESTION) {
      return Constants.ZENDESK_TICKET__TYPE__QUESTION_BEAUTIFIED;
    }
    if (type === Constants.ZENDESK_TICKET__TYPE__IMPROVEMENT_REQUEST) {
      return Constants.ZENDESK_TICKET__TYPE__IMPROVEMENT_REQUEST_BEAUTIFIED;
    }
    if (type === Constants.ZENDESK_TICKET__TYPE__BUG) {
      return Constants.ZENDESK_TICKET__TYPE__BUG_BEAUTIFIED;
    }
    if (type === Constants.ZENDESK_TICKET__TYPE__INSTALLATION) {
      return Constants.ZENDESK_TICKET__TYPE__INSTALLATION_BEAUTIFIED;
    }
    if (type === Constants.ZENDESK_TICKET__TYPE__SFMC_BUG) {
      return Constants.ZENDESK_TICKET__TYPE__SFMC_BUG_BEAUTIFIED;
    }
    if (type === Constants.ZENDESK_TICKET__TYPE__ACADEMY_QUESTION) {
      return Constants.ZENDESK_TICKET__TYPE__ACADEMY_QUESTION_BEAUTIFIED;
    }

    return '';
  },

  /**
   * Render support ticket product
   * @param {String} product - product assigned in DB
   * @returns {String} beautified string
   */
  renderSupportTicketProduct: (product) => {
    switch (product) {
      case Constants.ZENDESK_TICKET__PRODUCT__SEGMENT:
        return Constants.ZENDESK_TICKET__PRODUCT__SEGMENT__BEAUTIFIED;
      case Constants.ZENDESK_TICKET__PRODUCT__SEARCH:
        return Constants.ZENDESK_TICKET__PRODUCT__SEARCH__BEAUTIFIED;
      case Constants.ZENDESK_TICKET__PRODUCT__ACADEMY:
        return Constants.ZENDESK_TICKET__PRODUCT__ACADEMY__BEAUTIFIED;
      case Constants.ZENDESK_TICKET__PRODUCT__ENGAGE:
        return Constants.ZENDESK_TICKET__PRODUCT__ENGAGE__BEAUTIFIED;
      case Constants.ZENDESK_TICKET__PRODUCT__CONNECT:
        return Constants.ZENDESK_TICKET__PRODUCT__CONNECT__BEAUTIFIED;
    }

    return '';
  },

  /**
   * Function that converts HTML into plain text
   * @param {string} html - html text
   * @returns {string} plain text
   */
  convertToPlain: (html) => {
    // Create a new div element
    const tempDivElement = document.createElement('div');

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;

    // Retrieve the text property of the element
    return tempDivElement.textContent || tempDivElement.innerText || '';
  },

  /**
   * Calculates averageNPS based on the number of promoters, passive and detractors
   *  @param {Object} npsOverview - NPS object containing { promoters, passives, detractors }
   *  @returns {Number} NPS score
   */
  calculateAverageNPS: (npsOverview) => {
    // Make sure we have a value, otherwise return 0
    if (npsOverview) {
      const totalReviews = npsOverview.promoters + npsOverview.passives + npsOverview.detractors;

      return (totalReviews ?
        Math.round(((npsOverview.promoters / totalReviews) * 100) - ((npsOverview.detractors / totalReviews) * 100)) :
        '-'
      );
    } return 0;
  },

  /**
   * Renders the NPS score based on the NPS range ([-100, 100]) in 3 levels:
   *  Over 75% green, between 25-50% yellow, under 25% red
   *  @param {String} value - NPS score
   *  @returns {HTMLElement} returns the HTML tag to be printed
   */
  renderNPSRange: (value) => {
    let numberClass = 'slds-badge';

    // Divided NPS range ([-100, 100]) in 3 levels
    if (value >= 50) {
      // Over 50 (>75%), green
      numberClass += ' slds-theme_success';
    } else if (value < 50 && value > -50) {
      // Between -50 and 50 (25% - 50%), yellow
      numberClass += ' slds-theme_warning';
    } else if (value >= -100) {
      // Under -50 (<25%), red
      numberClass += ' slds-theme_error';
    }

    return (
      <span className={numberClass}>
        {value.toString()}
      </span>
    );
  },
};

Util.renderAdoptionDashboardLink.displayName = 'renderAdoptionDashboardLink';

export default Util;
